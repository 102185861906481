
const themes = [
    {
        name: 'Default',
        colorPrimary: '#101010',
        colorAccent: '#F44E77',
        colorSecondaryDark: '#16194F',
        colorBheti: '#641C1C',
        colorClothes: '#894119',
        colorBg: '#F0F3F4',
        colorBg2: '#10121A',
        colorBg3: '#111111',
        colorYellowish: '#FDEBD0',
        colorLavender: '#9A94FF',
        colorGreyDark: '#A6ACAF',
        colorGreyLight: '#191919',
        colorGreyLight2: '#1D1D1D',
        colorWhite: '#FFFFFF',
        colorWhiteIsh: '#B3B6B7',
        colorBlack: '#161B1C',
        colorNeutral: '#F2F6FF',
        colorGradientPrimary: 'linear-gradient(143.82deg, #554DDE 4.75%, rgba(85, 77, 222, 0) 61.12%)',
        colorGradientAccent: 'linear-gradient(132.34deg, #F44E77 -7.22%, rgba(244, 78, 119, 0) 77.9%)',
        colorGradientPrimary2: 'linear-gradient(171.21deg, #541010 -10.74%, #BDC3C7 112.82%, #BDC3C7 112.82%)',
        colorGradientPrimary3: 'linear-gradient(450.81deg, #540000 -17.99%, #aeb0b0 111.32%, #aeb0b0 131.32%)',
        colorGray: '#6c757d',
        colorGrey: '#343a40',
        colorFont: '#515A5A',
        colorGrey1: '#f8f9fa',
        colorGrey2: '#e9ecef',
        colorGrey3: '#dee2e6',
        colorGrey4: '#ced4da',
        colorGrey5: '#adb5bd',
        colorGrey6: '#6c757d',
        colorGrey7: '#495057',
        colorGrey8: '#343a40',
        colorBorder: '#f8f9fa14',
        colorGrey9: '#212529b7',
        colorSlack: '#e91e62',
        colorSlackDeep: '#b8184d',
        colorLinkedIn: '#2266C2',
        colorLinkedInDeep: '#1b4c8f',
    },
    {
        name: 'light',
        colorPrimary: '#554DDE',
        colorAccent: '#F44E77',
        colorSecondaryDark: '#16194F',
        colorBheti: '#540000',
        colorBg: '#191D2B',
        colorBg2: '#10121A',
        colorBg3: '#10121A',
        colorYellowish: '#FDEBD0',
        colorLavender: '#9A94FF',
        colorGreyDark: '#A6ACAF',
        colorGreyLight: '#10121A',
        colorGreyLight2: '#10121A',
        colorWhite: '#FFFFFF',
        colorBlack: '#000000',
        colorNeutral: '#F2F6FF',
        colorGradientPrimary: 'linear-gradient(143.82deg, #554DDE 4.75%, rgba(85, 77, 222, 0) 61.12%)',
        colorGradientAccent: 'linear-gradient(132.34deg, #F44E77 -7.22%, rgba(244, 78, 119, 0) 77.9%)',
        colorGradientPrimary2: 'linear-gradient(171.21deg, #554DDE -10.74%, #F44E77 112.82%, #F44E77 112.82%)',
        colorGradientPrimary3: 'linear-gradient(300.81deg, #554DDE -17.99%, #F44E77 111.32%, #F44E77 111.32%)',
        colorGray: '#6c757d',
        colorGrey: '#343a40',
        colorFont: '#a2a2a2',
        colorGrey1: '#f8f9fa',
        colorGrey2: '#e9ecef',
        colorGrey3: '#dee2e6',
        colorGrey4: '#ced4da',
        colorGrey5: '#adb5bd',
        colorGrey6: '#6c757d',
        colorGrey7: '#495057',
        colorGrey8: '#343a40',
        colorBorder: '#f8f9fa14',
        colorGrey9: '#212529b7',
    },
]

export default themes;